"use client";

import NextError from "next/error";

interface GlobalErrorProps {
  error: Error & { digest?: string };
}

const GlobalError = ({ error }: GlobalErrorProps) => {
  // eslint-disable-next-line no-console
  console.warn(error);

  return (
    <html>
      <body>
        {/* `NextError` is the default Next.js error page component. Its type
        definition requires a `statusCode` prop. However, since the App Router
        does not expose status codes for errors, we simply pass 0 to render a
        generic error message. */}
        <NextError statusCode={0} />

        <div className="size-full flex flex-col gap-2 items-center justify-center min-h-80">
          <h2 className="typography-header-2">Unexpected error 🤕</h2>

          <p className="typography-body-default-regular text-typography-primary-medium-contrast">
            The Superfiliate team has been warned about it and a fix should soon
            be live. Please, Try again later
          </p>
        </div>
      </body>
    </html>
  );
};

export default GlobalError;
